export const useSwiper = () => {
  const swiperInstance = ref({});

  const onSwiper = (swiper) => {
    swiperInstance.value = swiper;
    swiperInstance.value.on('slidesLengthChange', () =>
      swiperInstance.value.update(),
    );
  };

  const isEnd = computed(() => swiperInstance.value.isEnd);

  const isBeginning = computed(() => swiperInstance.value.isBeginning);

  const slidePrev = () => swiperInstance.value.slidePrev();

  const slideNext = () => swiperInstance.value.slideNext();

  const slideTo = () => swiperInstance.value.slideTo();

  const activeIndex = computed(() => swiperInstance.value.activeIndex);

  return {
    onSwiper,
    swiper: swiperInstance,
    slideNext,
    slidePrev,
    isEnd,
    isBeginning,
    slideTo,
    activeIndex,
  };
};
